<template>
  <div class="my-profile">

    <div class="profile-form-wrapper">
      <input type="file" ref="photoinput" accept="image/x-png,image/gif,image/jpeg,image/webp" @change="receiveFile" style="display: none">
      <img :src="'/img/profile-img.png'" class="profile-avatar" v-if="client['photo'] === null" @click="runUploader()" />
      <img class="profile-avatar" :src="client['photo']" @click="runUploader()" :class="client['photo'].includes('blob') ? '' : 'br-50'" v-else>
      <div class="name-group">
        <div class="form-group">
          <label>Фамилия</label>
          <input type="text" v-model="client['surname']" />
        </div>
        <div class="form-group">
          <label>Имя</label>
          <input type="text" v-model="client['name']" />
        </div>
      </div>
      <div class="form-group">
        <label>Номер телефона</label>
        <input type="text" v-model="client['human_phone']" disabled style="cursor:not-allowed" />
      </div>
      <div class="form-group">
        <label>Электронная почта</label>
        <input ref="emailField" type="email" v-model="client['email']" />
      </div>
<!--      <div class="form-group">-->
<!--        <label>Telegram</label>-->
<!--        <input type="text" v-model="client['telegram']" />-->
<!--      </div>-->

      <h4>Уведомления о доставке</h4>
      <div class="profile-checkboxes">
        <label class="checkbox-button">
          <input type="checkbox" class="checkbox-button__input" v-model="client['notify_email']">
          <span class="checkbox-button__control"></span>
          <span class="checkbox-button__label">Электронная почта</span>
        </label>

        <label class="checkbox-button">
          <input type="checkbox" class="checkbox-button__input" v-model="client['notify_sms']">
          <span class="checkbox-button__control"></span>
          <span class="checkbox-button__label">SMS</span>
        </label>

        <label class="checkbox-button" v-if="client['telegram_id'] !== null">
          <input type="checkbox" class="checkbox-button__input" v-model="client['notify_telegram']">
          <span class="checkbox-button__control"></span>
          <span class="checkbox-button__label">Телеграм</span>
        </label>
        <a class="c-black" :href="`https://t.me/theterminala_bot?start=${client['auth_token']}`" v-if="client['auth_token'] && client['telegram_id'] === null" target="_blank">
          Подключить телеграм бот для уведомлений
        </a>

      </div>

      <div class="save-button">
        <div class="profile-errors" v-if="errors.length > 0">
          <h5>Ошибка</h5>
          <p v-for="error in errors">{{error}}</p>
        </div>
        <span v-show="saving">сохранение ...</span>
        <div v-show="!saving">
          <span ref="msg" class="hidden">Профиль сохранен</span>
          <button ref="btn" class="black-btn " @click="save">Сохранить</button>
        </div>
      </div>
      <div>
        <a href="/logout" class="connect" data-confirm="Вы уверены?" data-method="post" rel="nofollow">
          <span>Выйти</span>
          <img :src="'/img/shevron-right.svg'" alt="" class="shevron-connect">
        </a>
      </div>

    </div>

  </div>
</template>

<script>
  export default {
    data: () => {
      return {
        client: {},
        saving: false,
        remove_photo: false,
        errors: []
      }
    },
    created() {
      this.client = window.gon.client
    },
    methods: {
      runUploader() {
        this.$refs.photoinput.value= null;
        this.$refs.photoinput.click();
      },
      receiveFile(event) {
        const [img] = event.target.files
        if (img) {
          this.client.photo = URL.createObjectURL(img)
          this.remove_photo = false
        }
      },
      save() {
        this.errors = []

        if (this.isEmailError) {
          this.errors.push("некорректный email")
        }

        if (this.errors.length === 0) {
          const _this = this
          this.saving = true
          const formData = new FormData();
          formData.append('client[name]',this.client.name)
          formData.append('client[surname]',this.client.surname)
          formData.append('client[email]',this.client.email)
          formData.append('client[notify_email]',this.client.notify_email)
          formData.append('client[notify_sms]',this.client.notify_sms)
          formData.append('client[notify_telegram]',this.client.notify_telegram)
          if (this.$refs.photoinput.files.length > 0) {
            formData.append('client[photo]', this.$refs.photoinput.files[0])
          } else if (this.remove_photo) {
            formData.append('client[remove_photo]','1')
          }

          fetch('/clients/save', {
            headers: {'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),'X-Requested-With' : 'XMLHttpRequest'},
            method: 'POST',
            body: formData})
              .then(response => response.json())
              .then(data => {
                // console.log(_this.$refs['btn'])
                _this.$refs['btn'].classList.add('hidden')
                _this.$refs['msg'].classList.remove('hidden')
                setTimeout(() => {
                  _this.$refs['msg'].classList.add('hidden')
                  _this.$refs['btn'].classList.remove('hidden')
                },2000)
                _this.saving = false
              })
        }


      }
    },
    computed: {
      isEmailError() {
        if (this.client['email']?.trim().length > 0) {
          const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
          return !reg.test(this.$refs['emailField'].value)
        } else {
          return false
        }


      }
    }
  }
</script>

<style scoped>
  .my-profile {
    max-width: 480px;
    margin: 0 auto;
  }

  .profile-form-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 100px;
    gap: 32px;
  }

  .profile-avatar {
    width: 200px;
    height: 200px;
    cursor: pointer;
    object-fit: contain;
  }

  .name-group {
    display: flex;
    gap: 32px;
  }

  .name-group .form-group {
    width: 100%;
  }

  .form-group {
    display: flex;
    flex-direction: column;
  }

  .form-group label {
    color: rgba(0, 0, 0, 0.5);
  }

  .form-group input[type=text],input[type=email] {
    border: none;
    border-bottom: 1px solid #000;
    padding: 12px 8px;
  }

  .form-group input[type=text]:focus, input[type=email]:focus {
    outline: none;
  }

  h4 {
    font-size: 30px;
    margin-top: 60px;
  }

  .profile-checkboxes {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 40px;
  }

  .profile-checkboxes .checkbox-button__control {
    color: rgba(0, 0, 0, 0.5);
  }

  .save-button {
    padding-bottom: 60px;
    border-bottom: 1px solid #D9D9D9;
    margin-bottom: 60px;
  }

  .profile-errors {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .profile-errors p {
    color: #e40000;
  }

</style>